import React, { useState, useEffect, useCallback } from "react";
import LanguageSelection from "./components/LanguageSelection";
import StoryDisplay from "./components/StoryDisplay";
import Highlights from "./components/Highlights";
import Settings from "./components/Settings";
import Header from "./components/Header";
import { fetchStory, fetchExplanationAndTranslation } from "./api/chatGPT";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as Dialog from "@radix-ui/react-dialog";
import "./App.css";
import "tailwindcss/tailwind.css";
import "./styles/header.css";
import Flashcards from "./components/Flashcards";

import { Outlet } from "react-router-dom";

import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const App = () => {
  const [languageSelection, setLanguageSelection] = useState(null);
  const [story, setStory] = useState("");
  const [highlights, setHighlights] = useState([]);
  const [highlightLoader, setHighlightLoader] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isStoryLoading, setIsStoryLoading] = useState(false);
  const [topic, setTopic] = useState("");
  const [storyType, setStoryType] = useState("story"); // Add a new state for storyType

  const handleSettingsClick = () => {
    console.log("Show settings");
    setSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    console.log("Close settings");
    setSettingsOpen(false);
  };

  useEffect(() => {
    document.title = "Storylingo";
  }, []);

  const handleRemoveHighlight = (index) => {
    // Remove the highlight from the state
    setHighlights((prevHighlights) => {
      const updatedHighlights = [...prevHighlights];
      updatedHighlights.splice(index, 1);
      return updatedHighlights;
    });

    // Update the localStorage
    const updatedHighlights = [...highlights];
    updatedHighlights.splice(index, 1);
    localStorage.setItem("highlights", JSON.stringify(updatedHighlights));
  };

  const generateAndStoreStory = useCallback(
    (language, level) => {
      if (!language || !level) {
        const storedLanguageSelection =
          localStorage.getItem("languageSelection");

        if (!storedLanguageSelection) return;

        const parsedStoredLanguageSelection = JSON.parse(
          storedLanguageSelection
        );

        language = parsedStoredLanguageSelection.language;
        level = parsedStoredLanguageSelection.level;
      }

      console.log("generateAndStoreStory", language, level, storyType, topic);

      setIsStoryLoading(true);
      fetchStory(language, level, storyType, topic)
        .then((generatedStory) => {
          let storedStories = localStorage.getItem(`stories_${language}`);
          if (storedStories) {
            storedStories = JSON.parse(storedStories);
          } else {
            storedStories = [];
          }
          localStorage.setItem(
            `stories_${language}`,
            JSON.stringify([...storedStories, generatedStory])
          );
          setStory(generatedStory);
          setIsStoryLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching story:", error);
        });
    },
    [storyType, topic]
  );

  useEffect(() => {
    const storedLanguageSelection = localStorage.getItem("languageSelection");
    if (storedLanguageSelection) {
      const parsedStoredLanguageSelection = JSON.parse(storedLanguageSelection);
      setLanguageSelection(parsedStoredLanguageSelection);
      const storedStories = localStorage.getItem(
        `stories_${parsedStoredLanguageSelection.language}`
      );
      if (storedStories) {
        const parsedStoredStories = JSON.parse(storedStories);
        const reversedStories = parsedStoredStories.slice().reverse();

        setStory(reversedStories[0]);
      } else {
        generateAndStoreStory(
          parsedStoredLanguageSelection.language,
          parsedStoredLanguageSelection.level
        );
      }
    }
  }, [generateAndStoreStory]);

  const handleLanguageSelection = (selection) => {
    setLanguageSelection(selection);
    localStorage.setItem("languageSelection", JSON.stringify(selection));

    generateAndStoreStory(selection.language, selection.level);

    // Close the settings dialog
    setSettingsOpen(false);
  };

  const handleStoryTypeChange = (e) => {
    setStoryType(e.target.value);
  };

  useEffect(() => {
    if (languageSelection) {
      const storedHighlights = localStorage.getItem(
        `highlights_${languageSelection.language}`
      );
      if (storedHighlights) {
        setHighlights(JSON.parse(storedHighlights));
      } else {
        setHighlights([]);
      }
    }
  }, [languageSelection]);

  const handleTopicChange = (e) => {
    console.log("handleTopicChange", e.target.value);
    setTopic(e.target.value);
  };

  const handleHighlightSelection = async (selectedText, context) => {
    setHighlightLoader(selectedText);
    console.log(handleHighlightSelection, selectedText, context);

    const highlightData = await fetchExplanationAndTranslation(
      languageSelection.language,
      selectedText,
      context
    );

    const [translation, explanation] = highlightData
      .split("\n")
      .map((line) => line.trim());

    const highlight = { originalText: selectedText, translation, context };

    console.log("Add new higlight", highlight, highlights, explanation);

    setHighlights([...highlights, highlight]);
    localStorage.setItem(
      `highlights_${languageSelection.language}`,
      JSON.stringify([...highlights, highlight])
    );

    setHighlightLoader("");

    return highlight;
  };

  const LearnRoute = () => {
    return (
      <div>
        {!languageSelection ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <LanguageSelection
              handleLanguageSelection={handleLanguageSelection}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="p-4">
              <StoryDisplay
                story={story}
                handleHighlightSelection={handleHighlightSelection}
                isLoading={isStoryLoading}
              />
              <hr className="my-4"></hr>
              <div className="mb-4 flex-col">
                <div className="flex-col">
                  <select
                    name="storyType"
                    id="storyType"
                    value={storyType}
                    onChange={handleStoryTypeChange}
                    className="mr-6"
                  >
                    <option value="story">Story</option>
                    <option value="dialogue">Dialogue</option>
                  </select>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded my-4"
                    onClick={generateAndStoreStory}
                  >
                    New {storyType === "story" ? "Story" : "Dialogue"}
                  </button>
                </div>
                {storyType === "dialogue" && (
                  <div className="flex flex-col">
                    <label
                      htmlFor="topicInput"
                      className="mb-1 font-medium text-gray-700"
                    >
                      Optional topic seed:
                    </label>
                    <input
                      type="text"
                      id="topicInput"
                      name="topicInput"
                      onChange={handleTopicChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 box-border"
                      style={{ boxSizing: "border-box" }}
                      placeholder='For example, "Ordering food at a restaurant"'
                    />
                  </div>
                )}
              </div>
            </div>

            <Highlights
              highlights={highlights}
              highlightLoader={highlightLoader}
              onRemoveHighlight={handleRemoveHighlight}
            />
          </div>
        )}
      </div>
    );
  };

  const Root = () => {
    return (
      <>
        <Header handleSettingsClick={handleSettingsClick} />
        <div style={{ marginTop: 70 }}>
          <Outlet />
        </div>
        <Dialog.Root open={settingsOpen} onOpenChange={handleCloseSettings}>
          <Dialog.Trigger asChild>
            <div />
          </Dialog.Trigger>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <Settings
              handleLanguageSelection={handleLanguageSelection}
              currentSelection={languageSelection}
            />
            <button
              onClick={handleCloseSettings}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </Dialog.Content>
        </Dialog.Root>
      </>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          index: true,
          element: <LearnRoute />,
        },
        {
          path: "/remember",
          element: <Flashcards flashcards={highlights} />,
        },
      ],
    },
  ]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
};

export default App;

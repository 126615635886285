import React, { useEffect } from "react";

const LanguageSelection = ({ handleLanguageSelection, currentSelection }) => {
  const [language, setLanguage] = React.useState("");
  const [level, setLevel] = React.useState("");

  useEffect(() => {
    if (currentSelection) {
      setLanguage(currentSelection.language);
      setLevel(currentSelection.level);
    }
  }, [currentSelection]);

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleSubmit = () => {
    if (language && level) {
      handleLanguageSelection({ language, level });
    }
  };

  return (
    <div className="p-4">
      <h4 className="text-xl font-bold mb-4">Select Language and Level</h4>
      <div className="mb-4">
        <select
          value={language}
          onChange={(event) => handleChange(event, setLanguage)}
          className="w-full p-2 border-2 border-gray-300 rounded"
        >
          <option value="" disabled>
            Language
          </option>
          <option value="Mandarin">Mandarin</option>
          <option value="Spanish">Spanish</option>
          <option value="Hindi">Hindi</option>
          <option value="Bengali">Bengali</option>
          <option value="Portuguese">Portuguese</option>
          <option value="Japanese">Japanese</option>
          <option value="Marathi">Marathi</option>
          <option value="Telugu">Telugu</option>
          <option value="Wu Chinese">Wu Chinese</option>
          <option value="Turkish">Turkish</option>
          <option value="Korean">Korean</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Vietnamese">Vietnamese</option>
          <option value="Italian">Italian</option>
          <option value="Danish">Danish</option>
          <option value="Swedish">Swedish</option>
          <option value="Greek">Greek</option>
          <option value="Czech">Czech</option>
        </select>
      </div>
      <div className="mb-4">
        <select
          value={level}
          onChange={(event) => handleChange(event, setLevel)}
          className="w-full p-2 border-2 border-gray-300 rounded"
        >
          <option value="" disabled>
            Skill Level
          </option>
          <option value="First time speaker">First time speaker</option>
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
      </div>
      <button
        onClick={handleSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        Start Learning
      </button>
    </div>
  );
};

export default LanguageSelection;

const API_URL = "/api/chatgpt";

const fetchStory = async (language, level, storyType, topic) => {
  if (!topic || topic === "") {
    topic = "Ordering food at a restaurant";
  }

  console.log("fetchStory", language, level, storyType, topic);

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "fetchStory",
        language,
        level,
        storyType,
        topic,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.result;
  } catch (error) {
    console.error("Error fetching story:", error);
    throw error;
  }
};

const fetchExplanationAndTranslation = async (language, text, context) => {
  console.log("fetchExplanationAndTranslation", language, text, context);

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "fetchExplanationAndTranslation",
        language,
        text,
        context,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.result;
  } catch (error) {
    console.error("Error fetching explanation and translation:", error);
    throw error;
  }
};

export { fetchStory, fetchExplanationAndTranslation };

import React from "react";

function highlightsToAnki(highlights) {
  const delimiter = "\t";
  const newLine = "\n";

  const ankiText = highlights
    .map((highlight) => {
      const originalText = highlight.originalText;
      const translation = highlight.translation;
      const explanation = highlight.explanation;

      return `${originalText}${delimiter}${translation}${delimiter}${explanation}`;
    })
    .join(newLine);

  return ankiText;
}

const DownloadAnkiButton = ({ highlights }) => {
  const downloadAnkiFile = () => {
    const ankiText = highlightsToAnki(highlights);
    const blob = new Blob([ankiText], { type: "text/plain;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "anki-import.txt";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={downloadAnkiFile}
      className="font-bold rounded mb-4 text-blue-700"
    >
      Download for Anki
    </button>
  );
};

export default DownloadAnkiButton;

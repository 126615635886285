import React from "react";
import DownloadAnkiButton from "./DownloadAnkiButton";

const Highlights = ({ highlights, onRemoveHighlight, highlightLoader }) => {
  const reversedHighlights = highlights.slice().reverse();

  return (
    <div className="p-4">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4 className="text-xl font-bold mb-4">Highlights</h4>
        <DownloadAnkiButton highlights={highlights} />
      </div>
      {highlightLoader && (
        <div className="bg-white rounded shadow-md p-4 mb-4 relative">
          <p className="text-lg font-semibold mb-2">{highlightLoader}</p>
          <div className="spinner small" />
        </div>
      )}
      {reversedHighlights.map((highlight, index) => (
        <div
          key={index}
          className="bg-white rounded shadow-md p-4 mb-4 relative"
        >
          <button
            className="text-m text-slate-500 hover:text-red-600 absolute top-1 right-1"
            onClick={() =>
              onRemoveHighlight(reversedHighlights.length - 1 - index)
            }
          >
            ×
          </button>
          <p className="text-lg font-semibold mb-2">{highlight.originalText}</p>
          <p className="text-base mb-1">{highlight.translation}</p>
          <p className="text-sm">{highlight.explanation}</p>
        </div>
      ))}
    </div>
  );
};

export default Highlights;

// Flashcard.js

import React, { useState } from "react";
import "./Flashcard.css";

const Flashcard = ({ originalText, translation, context }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardFlip = () => {
    console.log("handleCardFlip", "isFlipped", isFlipped, "setIsFlipped");
    setIsFlipped(!isFlipped);
  };

  const originalTextIndex = context.indexOf(originalText);
  const beforeOriginalText = context.slice(0, originalTextIndex);
  const afterOriginalText = context.slice(
    originalTextIndex + originalText.length
  );

  return (
    <div
      className="Flashcard"
      onClick={handleCardFlip}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "500px",
        maxWidth: "90vw",
        minHeight: "200px",
        padding: "20px",
        border: "1px solid black",
        borderRadius: "5px",
        backgroundColor: "white",
        cursor: "pointer",
      }}
    >
      <div
        className="OriginalText"
        style={{ fontWeight: "bold", textAlign: "center" }}
      >
        {originalText}
      </div>
      <div
        className="FlashCardContext"
        style={{ fontSize: "0.8em", fontStyle: "italic", textAlign: "center" }}
      >
        "{beforeOriginalText}
        <span style={{ fontWeight: "bold" }}>{originalText}</span>
        {afterOriginalText}"
      </div>
      {isFlipped && (
        <div className="Translation" style={{ marginTop: "20px" }}>
          {translation}
        </div>
      )}
    </div>
  );
};

export default Flashcard;

import React, { useRef } from "react";

const StoryDisplay = ({
  story,
  handleHighlightSelection,
  isLoading,
  handleNewStory,
}) => {
  const storyRef = useRef(null);

  const handleTextSelection = async (event) => {
    console.log("handleMouseUp", window.getSelection());

    const selectedText = window.getSelection().toString().trim();

    if (selectedText) {
      if (selectedText.length > 0) {
        const focusNode = window.getSelection().focusNode;
        const nodeData = focusNode.data;

        // Find the last occurrence of a dot (.) before the selected text
        const beginning =
          nodeData.lastIndexOf(".", window.getSelection().anchorOffset) + 1;

        // Find the next occurrence of a dot (.) after the selected text
        const end = nodeData.indexOf(".", window.getSelection().focusOffset);

        // Extract the context (sentence) using the beginning and end positions
        let context;
        if (end === -1) {
          context = nodeData.substring(beginning).trim();
        } else {
          context = nodeData.substring(beginning, end + 1).trim();
        }

        console.log("context", context);

        window.getSelection().removeAllRanges();
        await handleHighlightSelection(selectedText, context);
      }
    }
  };

  return (
    <div
      ref={storyRef}
      onMouseUp={handleTextSelection}
      onTouchEnd={handleTextSelection}
    >
      <h4 className="text-xl font-bold mb-4">Generated Story</h4>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="spinner" />
        </div>
      ) : (
        <div
          className="text-base"
          dangerouslySetInnerHTML={{ __html: story }}
        ></div>
      )}
    </div>
  );
};

export default StoryDisplay;

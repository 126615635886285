import React from "react";
import { GearIcon } from "@radix-ui/react-icons";
import { NavLink } from "react-router-dom";

const Header = ({ handleSettingsClick }) => {
  return (
    <header className="w-full fixed top-0 left-0 bg-white border-b border-gray-200 shadow-sm z-10">
      {/* Add other header content here */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
        <div className="headerButtonGroup">
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              `mx-2 ${isActive ? "selected" : isPending ? "pending" : ""}`
            }
          >
            Learn
          </NavLink>
          <NavLink
            to="/remember"
            className={({ isActive, isPending }) =>
              `mx-2 ${isActive ? "selected" : isPending ? "pending" : ""}`
            }
          >
            Remember
          </NavLink>
        </div>
        <div>
          <button
            onClick={handleSettingsClick}
            className="flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <GearIcon />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;

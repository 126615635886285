import React from "react";
import LanguageSelection from "./LanguageSelection";

const Settings = ({ handleLanguageSelection, currentSelection }) => {
  return (
    <div>
      <h4>Settings</h4>
      <LanguageSelection
        handleLanguageSelection={handleLanguageSelection}
        currentSelection={currentSelection}
      />
    </div>
  );
};

export default Settings;

// Flashcards.js

import React, { useState } from "react";
import Flashcard from "./Flashcard/Flashcard";

const Flashcards = ({ flashcards }) => {
  const [currentIndex, setCurrentIndex] = useState(generateRandomIndex());

  function generateRandomIndex() {
    return Math.floor(Math.random() * flashcards.length);
  }

  function handleNextClick() {
    let newIndex = generateRandomIndex();
    while (newIndex === currentIndex) {
      newIndex = generateRandomIndex();
    }
    setCurrentIndex(newIndex);
  }

  if (flashcards.length === 0) {
    return (
      <div>
        No flashcards yet. Go back to the "Learn" page and try highlighting some
        text.
      </div>
    );
  }

  return (
    <div
      className="FlashcardsContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Flashcard
        key={currentIndex} // Add this line
        originalText={flashcards[currentIndex].originalText}
        translation={flashcards[currentIndex].translation}
        context={flashcards[currentIndex].context}
      />
      {flashcards.length > 1 && (
        <div className="FlashcardsNavigation">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded my-4"
            onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Flashcards;
